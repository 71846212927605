import {Injectable, Input} from '@angular/core';
import {
    AccountingChargingSession,
    ChargingSession,
    ChargingSessionList,
    Evse,
    Vehicle
} from '@io-elon-common/frontend-api';
import { download } from '../../../helper/util-functions';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { ApiService } from '../../../../services/api-handlers/api.service';
import { VehicleService } from '../../../../modules/vehicle/service/vehicle.service';
import { EvseService } from '../../../../modules/evse/service/evse.service';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { DetailsDialogComponent } from './details-dialog/details-dialog.component';
import { DurationPipe } from '../../../helper/duration.pipe';
import { MatPaginator } from '@angular/material/paginator';
import { SystemService } from 'src/app/services/api-handlers/system.service';
import {ChargingSessionsService} from "../../../../modules/charging-session/service/chargingSession.service";
import {AuthService} from "../../../guards/auth.service";
import {AbstractReorderTableComponent} from '../AbstractReorderTableComponent';

@Injectable()
export abstract class ChargingsTable extends AbstractReorderTableComponent {
    abstract paginator: MatPaginator;
    abstract accountingPaginator: MatPaginator;
    public accountingPageIndex = 0;
    private static EMPTY = Promise.resolve(undefined);
    public columnNames: Map<string, string> = new Map([
        ["vehicle", "Fahrzeug"],
        ["evse", "Ladepunkt"],
        ["start", "Start"],
        ["duration", "Dauer"],
        ["power", "Geladene Energie"],
        ["cost", "Kosten"],
        ["rfid", "Kostenstelle / RFID"],
        ["stopReason", "Grund für das Beenden"]]);

    @Input() vehicleId!: number;

    public readonly accountingDisplayedColumns = ['start', 'duration', 'power', 'cost', 'evse', 'vehicle', 'rfid'];
    public exportData: any[] = [];
    public accountingExportData: any[] = [];
    protected abstract readonly apiService: ApiService;
    protected abstract readonly vehicleService: VehicleService;
    protected abstract readonly evseService: EvseService;
    protected abstract readonly toastrService: ToastrService;
    protected abstract readonly durationPipe: DurationPipe;
    protected abstract readonly chargingService: ChargingSessionsService;
    protected abstract readonly systemService: SystemService;
    protected abstract readonly authService: AuthService

    chargingProcesses!: ChargingSession[];
    price!: string;

    dataSource = new MatTableDataSource<ChargingSession>([]);
    accountingDataSource = new MatTableDataSource<AccountingChargingSession>([]);
    selection = new SelectionModel<ChargingSession>(true, []);
    selectedIndex = 0;
    finished = false;
    error: null | string = null;

    evses: Promise<Evse | undefined>[] = [];
    vehicles: Promise<Vehicle | undefined>[] = [];
    displayStopChargeButton: boolean = false;
    downloads: {[key: number]: boolean} = {};
    private visibleEvseIds: Set<number> = new Set<number>();
    private visibleVehicleIds: Set<number> = new Set<number>();
    private canStopCache: Array<boolean | Promise<boolean>> = [];


    protected constructor(
        protected readonly dialog: MatDialog
    ) {
        super(dialog);
    }

    protected abstract getChargings(): Promise<ChargingSessionList>;
    public abstract isAllowContinue(cs: ChargingSession): Promise<boolean>;


    public showMetaDownload(): boolean {
        return this.authService.isAdmin();
    }

    public async refresh(): Promise<void> {
        try {
            this.dataSource = new MatTableDataSource([] as ChargingSession[]);
            this.dataSource.paginator = this.paginator;
            this.accountingDataSource.paginator = this.accountingPaginator;
            const result = await this.getChargings()

            result.list.sort((cp1: ChargingSession, cp2: ChargingSession) => cp2.tstStart - cp1.tstStart);
            this.price = (result.price * 100).toFixed(0) + " ct/kWh";
            this.chargingProcesses = result.list;
            this.dataSource.data = this.chargingProcesses;
            this.accountingDataSource.data = result.listEiched;
            this.createExportData();

        } catch (e) {
            console.error(e)
            this.error = 'Fehler beim Laden';
        } finally {
            this.finished = true;
        }
    }

    public async onInit(): Promise<void> {
        this.loadReorderConfig();
        const evses = await this.evseService.getAllPromise(false);
        evses.list.forEach(evse => this.visibleEvseIds.add(evse.id));

        const vehicles = await this.vehicleService.getAllPromise(false);
        vehicles.list.forEach(vehicle => this.visibleVehicleIds.add(vehicle.id));

        try {
            const result = this.getChargings()

            result.then(csList => {
                csList.list.sort((cp1: ChargingSession, cp2: ChargingSession) => cp2.tstStart - cp1.tstStart);
                this.price = (csList.price * 100).toFixed(0) + " ct/kWh";
                this.chargingProcesses = csList.list;
                this.dataSource.data = this.chargingProcesses;
                this.accountingDataSource.data = csList.listEiched;
                this.createExportData();
            }).catch(err => {
                console.error(err)
                this.error = "Fehler beim Laden"
            });

            this.dataSource = new MatTableDataSource([] as ChargingSession[]);
            this.dataSource.paginator = this.paginator;
            this.accountingDataSource = new MatTableDataSource([] as AccountingChargingSession[]);
            this.accountingDataSource.paginator = this.accountingPaginator;
        } catch (e) {
            this.error = 'Fehler beim Laden';
        } finally {
            this.finished = true;
        }
        this.displayStopChargeButton = (await this.systemService.getSystemInfo()).displayStopChargeButton;
    }

    public getVehicle(vehicleId: number): Promise<Vehicle | undefined> {
        if (!this.visibleVehicleIds.has(vehicleId)) {
            return ChargingsTable.EMPTY;
        }
        if (vehicleId === null || vehicleId === undefined) {
            return ChargingsTable.EMPTY
        }
        if (!this.vehicles[vehicleId]) {
            this.vehicles[vehicleId] = this.vehicleService.getPromise(vehicleId, false);
        }
        return this.vehicles[vehicleId];
    }

    selectRow(row: ChargingSession) {
        if (this.selectedIndex === row.tstStart) {
            this.selectedIndex = -1;
        } else {
            this.selectedIndex = row.tstStart;
        }
    }

    public getEvse(evseId: number): Promise<Evse | undefined> {
        if (!this.visibleEvseIds.has(evseId)) {
            return ChargingsTable.EMPTY;
        }
        if (evseId === null || evseId === undefined) {
            return ChargingsTable.EMPTY
        }
        if (!this.evses[evseId]) {
            this.evses[evseId] = this.evseService.getPromise(evseId, false);
        }
        return this.evses[evseId];
    }

    public async createExportData() {
        if (!this.chargingProcesses || this.chargingProcesses.length === 0) {
            return;
        }

        const vehicles = await this.vehicleService.getAllPromise(false);
        const evses = await this.evseService.getAllPromise(false);
        this.exportData = [];
        this.exportData[0] = ["Start",
            "Start (raw)",
            "Ende",
            "Ende (raw)",
            "Dauer",
            "Dauer (Millisekunden)",
            "Fahrzeug",
            "Fahrzeug ID",
            "Ladepunkt",
            "Ladepunkt ID",
            "RFID",
            "Energie (Kilowattstunden)",
            "Energie ca. (Kilowattstunden)",
            "Preis (Euro)",
            "Kostenstelle",
            "Flotte",
            "Flotte Id",
            "Standort",
            "Standort Id"];


        this.chargingProcesses.forEach(p => {
            const vehicle = vehicles.list.find(v => v.id === p.vehicleId);
            const evse = evses.list.find(e => e.id === p.evseId);
            this.exportData.push(
                [
                    new Date(p.tstStart),
                    p.tstStart,
                    p.tstEnd ? new Date(p.tstEnd) : "",
                    (p.tstEnd || 0),
                    (p.tstEnd ? this.durationPipe.transform(p.tstEnd - p.tstStart) : "--"),
                    (p.tstEnd ? (p.tstEnd - p.tstStart) : "--"),
                    (vehicle?.name || ""),
                    (p.vehicleId || ""),
                    (evse?.name || ""),
                    (p.evseId || ""),
                    (p.rfid || ""),
                    (p.energyStart !== undefined ? p.energyEnd ? (((p.energyEnd - p.energyStart) / 1000)) : "" : ""),
                    (p.energyMath ? ((p.energyMath / 1000)) : ""),
                    (p.price ? Number(p.price.toFixed(2)) : ""),
                    (p.kostenstelle || ""),
                    (vehicle?.fleet.name || ""),
                    (vehicle?.fleet.id || ""),
                    (evse?.basis.name || ""),
                    (evse?.basis.id || "")
                ]);
        });

        this.accountingExportData[0] = ["CPI", "TransactionId", "Start", "Stop", "RFID", "Kostenstelle", "Start Empfangszeitpunkt", "Stop Empfangszeitpunkt", "Start Energiemessung", "Stop Energiemessung", "Start Rohdaten", "Stop Rohdaten"]
        this.accountingDataSource.data.forEach(a => {
            this.accountingExportData.push([
                a.cpi, a.transactionId, a.rfid, a.kostenstelle, a.tstStart, a.tstEnd, a.tstStartRcv, a.tstEndRcv, a.energyStart, a.energyEnd, a.rawStart, a.rawEnd
            ])
        })
    }

    public details(cs: ChargingSession): void {
        // Otherwise the Details page would constantly send requests if vehicle/evse deleted
        if (cs.vehicleId) {
            this.getVehicle(cs.vehicleId);
            if (this.vehicles[cs.vehicleId] === undefined) {
                cs.vehicleId = undefined;
            }
        }

        this.getEvse(cs.evseId); //This internally fills "evses" Array
        if (this.evses[cs.evseId] === undefined) {
            cs.evseId = -1;
        }

        this.dialog.open(DetailsDialogComponent, {
            width: '80%',
            data: cs,
        });
    }

    public async downloadMeta(cs: ChargingSession): Promise<void> {
        this.downloads[cs.id] = true;
        const meta = await this.chargingService.getDetails(cs.id);
        delete this.downloads[cs.id];
        download("Details_" + cs.id + ".txt", meta.details);
    }

    public async stop(cs: ChargingSession): Promise<void> {
        const evseId = cs.evseId;
        try {
            const response = await this.evseService.stopCharging(evseId);
            if(response.success) {
                this.toastrService.info("Ladevorgang wird gestoppt");
            } else {
                console.error(response.result);
                this.toastrService.warning("Fehler beim stoppen des Ladevorgangs");
            }
        } catch (exc) {
            this.toastrService.warning("Fehler beim stoppen des Ladevorgangs");
            console.error(exc);
        }
        setTimeout(() => this.refresh(), 500)
    }

    public isStopped(cs: ChargingSession) {
        return cs.state === ChargingSession.StateEnum.Stopped;
    }

    public async continue(cs: ChargingSession) {
        const evseId = cs.evseId;
        try {
            const response = await this.evseService.continueCharging(evseId);
            if(response.success) {
                this.toastrService.info("Ladevorgang wird fortgesetzt.");
            } else {
                console.error(response.result);
                this.toastrService.warning("Fehler beim Fortsetzen des Ladevorgangs: " + response.result);
            }
        } catch (exc) {
            this.toastrService.warning("Fehler beim Fortsetzen des Ladevorgangs");
            console.error(exc);
        }
        setTimeout(() => this.refresh(), 500)
    }

    public canStop(cs: ChargingSession): boolean {
        if (!cs.evseId) {
            return false;
        }

        if (this.canStopCache[cs.evseId] === undefined) {
            this.canStopCache[cs.evseId] = this.evseService.getPromise(cs.evseId).then(e => this.canStopCache[cs.evseId] = e.canEdit);
        }

        const state = this.canStopCache[cs.evseId];
        if (typeof state === "boolean") {
            return state;
        }
        return false;
    }
}
