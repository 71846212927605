import {Component, Input} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import * as XLSX from 'xlsx';
import {BookType} from 'xlsx';
import {ToastrService} from 'ngx-toastr';
import {DatePipe} from '@angular/common';
import {download} from "../../helper/util-functions";

@Component({
  selector: 'app-file-exporter',
  templateUrl: './file-exporter.component.html',
  styleUrl: './file-exporter.component.scss'
})

export class FileExporterComponent {
    @Input() fileName: string = "export";
    @Input() data!: any[][];
    @Input() icon = "download"
    @Input() tooltip = "Liste herunterladen"

    public csvDialect: Array<{name: string, date: string, fs: string, ds: string}> = [
        { name: "DE", date: "dd.MM.yyyy HH:mm:ss", fs: ";", ds: ","},
        { name: "US", date: "MM/dd/yyyy HH:mm:ss", fs: ",", ds: "."},
        { name: "UK", date: "dd/MM/yyyy HH:mm:ss", fs: ",", ds: "."}
    ];

    constructor(
        private readonly dialog: MatDialog,
        private readonly toastrService: ToastrService,
        private readonly datePipe: DatePipe
    ) {

    }

    public export<T extends BookType>(type: T, dateFormat?: T extends 'csv' ? string : never, fieldSeperator?: T extends 'csv' ? string : never, decimalSeperator?: T extends 'csv' ? string : never): void {
        const nowString = this.datePipe.transform(new Date(), 'dd-MM-yyyy hh:mm:ss');
        if (this.data.length === 0) {
            this.toastrService.warning("Keine Daten")
            return;
        }

        const book = XLSX.utils.book_new();
        const filename = this.fileName + "-" + nowString + "." + type;

        switch (type) {
            case "csv":
                const copy: string[][] = [];
                for(const row of this.data) {
                    const rowCopy: string[] = [];
                    for(const cell of row) {
                        if(cell instanceof Date) {
                            rowCopy.push(this.datePipe.transform(cell, dateFormat as string) || "Invalid Date");
                        } else {
                            switch (typeof cell) {
                                case "string":
                                    rowCopy.push(cell);
                                    break;
                                case "number":
                                    rowCopy.push(cell.toString().replaceAll(".", decimalSeperator!));
                                    break;
                                case "boolean":
                                    rowCopy.push(cell.toString());
                                    break;
                                case "object":
                                    if(cell === null) {
                                        rowCopy.push("");
                                    } else {
                                        rowCopy.push(cell.toString());
                                    }
                                    break;
                                case "bigint":
                                    rowCopy.push(cell.toString().replaceAll(".", decimalSeperator!));
                                    break;
                                case "undefined":
                                    rowCopy.push("");
                                    break;
                                case "symbol":
                                    rowCopy.push("");
                                    break;
                                case "function":
                                    rowCopy.push("");
                                    break;
                            }
                        }
                    }
                    copy.push(rowCopy);
                }

                const sheet = XLSX.utils.aoa_to_sheet(copy);
                XLSX.utils.book_append_sheet(book, sheet);
                XLSX.writeFile(book, filename, {
                    bookType: type,
                    FS: fieldSeperator
                });
                break;
            default: {
                const sheet = XLSX.utils.aoa_to_sheet(this.data);
                XLSX.utils.book_append_sheet(book, sheet);
                XLSX.writeFile(book, filename, { bookType: type });
                break;
            }
        }

        this.dialog.closeAll()
    }
}
