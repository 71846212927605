<div *ngIf="(fleet | async) as fleet; else loading" class="d-flex flex-column gap-3 align-items-stretch">
    <h1>{{fleet.base.name}}</h1>
    <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
        <mat-tab label="Energie">
            <ng-template matTabContent>
                <app-basis-details-view [fleet]="fleet"></app-basis-details-view>
            </ng-template>
        </mat-tab>
        <mat-tab label="Ladepunkt Gesundheitshistorie" *ngIf="hasEvseStatisticViewPermission">
            <ng-template matTabContent>
                <ng-container *ngIf="(evses | async) as evses; else loading">
                    <app-evse-health-history-view [evses]="getEvsesInBasis(evses.list, fleet.base.id)"></app-evse-health-history-view>
                </ng-container>
            </ng-template>
        </mat-tab>
        <mat-tab label="Ladepunkt Gesundheitsdiagramme" *ngIf="hasEvseStatisticViewPermission">
            <ng-template matTabContent>
                <ng-container *ngIf="(evses | async) as evses; else loading">
                    <app-evse-health-diagram-list [evses]="getEvsesInBasis(evses.list, fleet.base.id)"></app-evse-health-diagram-list>
                </ng-container>
            </ng-template>
        </mat-tab  >
        <mat-tab *ngIf="canViewNotes" label="Notizen">
            <ng-template matTabContent>
                <div class="p-2 bd-highlight">
                    <div class="with-background">
                        <app-note-table  [source]="fleet.base" [sourceType]="NoteRelatedObject.Basis"></app-note-table>
                    </div>
                </div>
            </ng-template>
        </mat-tab>
    </mat-tab-group>
</div>

<ng-template #loading>
    <div *ngIf="error">
        <h1>Hinweis</h1>
        {{error}}
    </div>
    <div *ngIf="!error">
        Loading...
    </div>
</ng-template>
