import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {OcppUplinkService} from '../../../../ocpp-uplinks/service/ocpp-uplink.service';
import {BehaviorSubject} from 'rxjs';
import {OcppUplink, OcppUplinkList} from '@io-elon-common/frontend-api';
import {FleetService} from "../../../../vehicle/service/fleet.service";
import {AuthService} from "../../../../../shared/guards/auth.service";

@Component({
    selector: 'app-actor-arg',
    templateUrl: './actor-arg.component.html',
    styleUrls: ['./actor-arg.component.scss']
})
export class ActorArgComponent implements OnInit {

    @Input()
    public key = "";

    @Input()
    public name = "";

    @Input()
    public type = "text";

    @Input()
    public model? = "";

    @Input()
    public isRequired = true;

    @Output()
    public modelChange = new EventEmitter<string>();

    public host = location.hostname;
    public secure = location.protocol === "https:";
    public fleetId!: BehaviorSubject<number | undefined>
    public withoutFleetId_ = false;
    public canEnableOldPrefix!: boolean;
    public setStatusPollIntervallSeconds: boolean = false;
    public setMeterPollIntervallSeconds: boolean = false;


    public constructor(
        private readonly ocppUplinkService: OcppUplinkService,
        private readonly fleetService: FleetService,
        private readonly authService: AuthService
    ) {
    }

    public ngOnInit(): void {
        if (this.key === 'chargePointIdentity') {
            this.fleetId = this.fleetService.selectedFleet;
            if(!this.model) {
                this.model = this.fleetId.getValue() + "/"
            }
            this.withoutFleetId_ = this.model?.indexOf("/") == -1
            this.canEnableOldPrefix = this.authService.isDeveloper() || this.withoutFleetId_;
        }

        this.setStatusPollIntervallSeconds = this.key === 'statusPollIntervallSeconds' && (this.model !== undefined && this.model !== "" && this.model !== "null");
        this.setMeterPollIntervallSeconds = this.key === 'meterPollIntervallSeconds' && (this.model !== undefined && this.model !== "" && this.model !== "null");
    }

    public getUplinks(): BehaviorSubject<OcppUplinkList | undefined> {
        return this.ocppUplinkService.getAll();
    }

    public get cpiVal(): string {
        return this.model?.substr(this.model.indexOf("/") + 1) || ""
    }

    public set cpiVal(value: string) {
        if(this.withoutFleetId) {
            this.model = value;
        } else {
            this.model = this.fleetId.getValue() + "/" + value;
        }
    }

    public get withoutFleetId(): boolean {
        return this.withoutFleetId_
    }

    public set withoutFleetId(value: boolean) {
        this.withoutFleetId_ = value;
        this.cpiVal = this.cpiVal;
    }

    public getOcppUplinkDisplayName(ocppUplink: OcppUplink): string {
        if (ocppUplink.name === undefined || ocppUplink.name.trim().length === 0 || ocppUplink.name.startsWith("IO-Automatisch generiert")) {
            return '' + ocppUplink.userName + ' - ' + ocppUplink.cpi;
        }
        return ocppUplink.name;
    }

}
